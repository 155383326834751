/* https://css-tricks.com/box-sizing/#article-header-id-6 */
html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  font-family: "Courier New", Courier, monospace;
  font-size: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* css reset: */

input,
textarea,
select,
button {
  font-family: inherit;
  font-size: inherit;
}
a {
  color: inherit;
  text-decoration: none;
}
button {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  outline: none;
  text-transform: none;
  -webkit-appearance: button;
  font-size: inherit;
  font-family: inherit;
  letter-spacing: inherit;
  color: inherit;
}
input[type="checkbox"] {
  cursor: pointer;
}
input:disabled,
input[readonly],
select:disabled {
  cursor: text;
  color: #666;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* calendar: */
.rbc-overlay .rbc-event {
  font-size: 0.8rem;
  font-weight: bold;
  background-color: white;
  color: #1a1919;
  border-radius: 0;
  border: 1px solid #1a1919;
}
